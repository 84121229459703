<template>
    <div style="margin-top:40px;">
        <div class="columns">
            <div class="column is-4">
                <pdfform />
            </div>
            <div class="column is-0"></div>
            <div class="column is-7">
                <pdfTable />
            </div>
        </div>
    </div>
</template>

<script>
import pdfform from "@/components/pdf/pdfForm.vue"
import pdfTable from "@/components/pdf/pdfTable.vue"
export default{
    components:{
        pdfform,
        pdfTable
    }
}
</script>
