<template>
  <div>
    <h4 class="title">तिथि</h4>
    <div class="field">
      <label class="label">मिति छान्नुहोस्</label>
      <div class="control">
        <VNepaliDatePicker
          calenderType="English"
          placeholder="मिति छान्नुहोस्"
          format="YYYY-MM-DD"
          classValue="nepalidatepicker"
          value
          class="datepicker"
          @change="selectDate"
        />
      </div>
    </div>
    <div class="field">
      <label class="label">तिथिको नाम</label>
      <div class="control">
        <input
          class="input"
          v-model="form.name"
          type="text"
          placeholder="तिथिको नाम"
          style="border-radius: 20px"
        />
      </div>
    </div>
    <div class="field">
      <div class="control">
        <label class="checkbox">
          <input type="checkbox" @change="selectHolliday($event)" />
          बिदा छ
        </label>
      </div>
    </div>
    <button
      class="button submit pbutton"
      :class="{ 'is-loading': loading }"
      @click="saveVideo"
      v-if="edit == false"
    >
      सेभ गर्नुहोस
    </button>
    <button
      class="button submit pbutton"
      :class="{ 'is-loading': loading }"
      @click="updateVideo"
      v-else
    >
      अपडेट गर्नुहोस्
    </button>
  </div>
</template>

<script>
import axios from "axios";
import VNepaliDatePicker from "v-nepalidatepicker";
import { bus } from "../../main";
export default {
  data() {
    return {
      form: {
        name: "",
        is_holiday: 0,
        date_np: "",
      },
      loading: false,
      edit: false,
    };
  },
  components: {
    VNepaliDatePicker,
  },
  // created() {
  //   bus.$on("edit-video", (e) => {
  //     this.edit = true;
  //     this.form = e;
  //     this.fileName = this.image;
  //   });
  // },
  methods: {
    // A method that is called when the checkbox is clicked. It is used to set the value of the checkbox.
    selectHolliday(e) {
      e.target.checked == true
        ? (this.form.is_holiday = 1)
        : (this.form.is_holiday = 0);
    },
    // This method is used to set the value of the date selected by the user.
    selectDate(date) {
      this.form.date_np = date;
    },
    // Used to clear the form after the data is saved.
    clearForm() {
      this.form = {};
    },
    // This method is used to save the data entered by the user.
    saveVideo() {
      this.loading = true;
      axios
        .post("api/tithi/store", this.form)
        .then((res) => {
          this.loading = false;
          this.$notify.success({
            position: "bottom right",
            msg: "Event added successfully!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
          this.form = {};
          this.clearForm();
          bus.$emit("store", true);
        })
        .catch((err) => {
          this.loading = false;
          this.$notify.error({
            position: "bottom right",
            msg: "Event could not be stored!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
        });
    },
  },
};
</script>

<style scoped>
.nepalidatepicker {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 10px !important;
  transition: all 0.3s;
}

.nepalidatepicker:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.label {
  color: #6a6a6a;
  font-weight: 400;
}
.title {
  font-size: 1.5rem;
  margin-bottom: 0px;
  color: #254594;
  margin-bottom: 10px;
}
.pbutton {
  background: #254594;
  color: #fff;
  border-radius: 34px;
  width: 153px;
  height: 40px;
  margin-top: 30px;
}
.pbutton:hover {
  color: #fff;
}
.pbutton:focus {
  color: #fff;
}
</style>
