<template>
    <div style="margin-top:40px;">
        <div class="columns">
            <div class="column is-4">
                <videoForm />
            </div>
            <div class="column is-0"></div>
            <div class="column is-7">
                <videoTable />
            </div>
        </div>
    </div>
</template>

<script>
import videoForm from "@/components/Video/videoForm.vue"
import videoTable from "@/components/Video/videoTable.vue"
export default{
    components:{
        videoForm,
        videoTable
    }
}
</script>
