<template>
  <div>
    <h4 class="title">PDF</h4>
    <div class="field">
      <label class="label"> फाइलको नाम</label>
      <div class="control">
        <input
          class="input"
          v-model="form.name"
          type="text"
          placeholder="फाइलको नाम"
        />
      </div>
    </div>
    <div class="field">
      <div class="label">फाइल</div>
      <div class="control">
        <div class="file has-name">
          <label class="file-label">
            <input class="file-input" type="file" ref="file" @change="upload" />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label"> Choose a file… </span>
            </span>
            <span class="file-name" style="min-width: 218px">
              {{ fileName }}
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="label">फोटो</div>
      <div class="control">
        <div class="file has-name">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              ref="photo"
              @change="uploadPhoto"
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label"> Choose a file </span>
            </span>
            <span class="file-name" style="min-width: 230px">
              {{ photoName }}
            </span>
          </label>
        </div>
      </div>
    </div>
    <button
      class="button submit pbutton"
      :class="{ 'is-loading': loading }"
      @click="savepdf"
      v-if="edit == false"
    >
      सेभ गर्नुहोस
    </button>
    <button
      class="button submit pbutton"
      :class="{ 'is-loading': loading }"
      @click="updatePdf"
      v-else
    >
      अपडेट गर्नुहोस्
    </button>
  </div>
</template>

<script>
import axios from "axios";
import { bus } from "../../main";
export default {
  data() {
    return {
      fileName: "",
      photoName: "",
      form: {

      },
      loading: false,
      pdfData: {},
      edit: false,
    };
  },
  created() {
    bus.$on("edit-pdf", (e) => {
      this.edit = true;
      this.form = e;
      console.log("this.form" ,this.form);
      this.fileName = this.form.pdf;
      this.photoName = this.form.image;
    });
  },
  methods: {
    clearForm(){
      this.form =  {};
      this.fileName = "";
      this.photoName = "";
    },  
    uploadPhoto() {
      this.photoName = this.$refs.photo.files[0].name;
      this.form.image = this.$refs.photo.files[0];
    },
    upload() {
      this.fileName = this.$refs.file.files[0].name;
      this.form.pdf = this.$refs.file.files[0];
    },
    updatePdf() {
      this.loading = true;
      const fd = new FormData();
      fd.append("name", this.form.name);
      fd.append("pdf", this.form.pdf);
      fd.append("image", this.form.image);
      axios
        .post("api/pdf/update/" + this.form.id , fd)
        .then((res) => {
          this.loading = false;
          this.$notify.success({
            position: "bottom right",
            msg: "PDF updated successfully!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
          this.clearForm();
          bus.$emit("pdf", true);
        })
        .catch((err) => {
          this.loading = false;
          this.$notify.error({
            position: "bottom right",
            msg: "PDF could not be updated!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
        });
    },
    savepdf() {
      this.loading = true;
      const fd = new FormData();
      fd.append("name", this.form.name);
      fd.append("pdf", this.form.pdf);
      fd.append("image", this.form.image);
      axios
        .post("/api/store-calendar-pdfs", fd)
        .then((res) => {
          this.loading = false;
          this.$notify.success({
            position: "bottom right",
            msg: "PDF added successfully!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
          this.clearForm();
          bus.$emit("pdf", true);
        })
        .catch((err) => {
          this.loading = false;
          this.$notify.error({
            position: "bottom right",
            msg: "PDF could not be stored!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
        });
    },
  },
};
</script>

<style scoped>
.label {
  color: #6a6a6a;
  font-weight: 400;
}
.title {
  font-size: 1.5rem;
  margin-bottom: 0px;
  color: #254594;
  margin-bottom: 10px;
}
.pbutton {
  background: #254594;
  color: #fff;
  border-radius: 34px;
  width: 153px;
  height: 40px;
  margin-top: 30px;
}
.pbutton:hover {
  color: #fff;
}
.pbutton:focus {
  color: #fff;
}
</style>
