import axios from "axios";

export default {
  namespaced: true,
  getters: {
    authenticated(state) {
      return state.token && state.user;
    },

    user(state) {
      return state.user;
    },
  },
  state: {
    token: null,
    user: null,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    async signIn(_, creds) {
      return await axios.post("/api/login", creds);
    },

    async attempt({ commit }, response) {
      commit("SET_TOKEN", response.data.token);
      commit("SET_USER", response.data.user);
    },

    async validate({ commit, state }, token) {
      if (token) {
        commit("SET_TOKEN", token);
      }
      if (!state.token) {
        return;
      }
    },

    async validateUser({commit, state},user){
        if(user){
            commit("SET_USER" ,user)
        }
        if(!state.user){
            return
        }
    },

    signOut({commit}){
      commit('SET_TOKEN', null);
      commit('SET_USER', null);
    }
  },
};
