<template>
  <div>
    <div class="columns" style="margin-top: 20px">
      <!-- <div class="column is-2">
                <h4 class="welcometitle">{{user.name}}!</h4>
                <span class="welcom">तपाईंलाई स्वागत छ।</span>
            </div> -->
      <div class="column is-10">
        <img src="@/../public/image/logo/logo.svg" alt="logo" />
      </div>
      <!-- <div class="column is-4"></div> -->
      <div
        class="column is-1"
        style="display: flex; align-items: center; justify-content: right"
      >
        <div class="user-icons">
          <div class="tooltip" @click="signOut">
            <img src="@/../public/image/icon/log-out.svg" alt="detail" />

            <span class="tooltiptext">Logout</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),

// A function that is called when the user clicks on the logout button. It calls the signOutAction
// function from the auth module. If the signOutAction function is successful, it will show a success
// message and redirect the user to the login page. If the signOutAction function is not successful, it
// will show a failure message.
    signOut() {
      this.signOutAction()
        .then(() => {
          this.$notify.success({
            position: "bottom right",
            msg: "Logout successfully!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
          this.$router.replace({
              name: 'Login'
          })
        })
        .catch((e) => {
          this.$notify.success({
            position: "bottom right",
            msg: "Could not logout!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
        });
    },
  },
};
</script>

<style scoped>
.welcometitle {
  font-size: 1.5rem;
  margin-bottom: 0px;
  font-weight: 500;
  color: #254594;
}
.welcome {
  font-size: 1.3rem;
  color: #8f8f8f;
}
.user-icons{
    cursor: pointer;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #254594;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -1px;
  right: 28px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
