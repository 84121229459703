import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import "ant-design-vue/dist/antd.css";
import VueNotify from "vuejs-notify";
import store from "./store";

require("@/store/localStorage");

store.dispatch("auth/validate", localStorage.getItem("token"));
store.dispatch("auth/validateUser", JSON.parse(localStorage.getItem("user")));

import {
  Button,
  Card,
  Divider,
  Icon,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Table,
  TreeSelect,
  Spin,
} from "ant-design-vue";
Vue.use(Button);
Vue.use(Table);
Vue.use(Card);
Vue.use(Icon);
Vue.use(Modal);
Vue.use(Divider);
Vue.use(Popconfirm);
Vue.use(Input);
Vue.use(Menu);
Vue.use(TreeSelect);
Vue.use(Spin);
Vue.use(VueNotify);
Vue.use(axios);

export const bus = new Vue();
axios.defaults.baseURL = `https://be-calendar.narmin.org.np/`;
// axios.defaults.baseURL = `http://192.168.1.93:8000/`;

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
