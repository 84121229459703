<template>
  <div>
    <a-menu v-model="current" mode="horizontal">
      <a-menu-item key="/" @click="selectMenu">
        <router-link :to="{ name: 'landingPage' }"> Home </router-link>
      </a-menu-item>
      <a-menu-item key="event" @click="selectMenu">
        <router-link :to="{ name: 'eventPage' }">
          Event
        </router-link></a-menu-item
      >
      <a-menu-item key="pdf" @click="selectMenu">
        <router-link :to="{ name: 'pdf' }"> PDF </router-link></a-menu-item
      >
      <a-menu-item key="video" @click="selectMenu">
        <router-link :to="{ name: 'video' }"> Video </router-link></a-menu-item
      >
    </a-menu>
  </div>
</template>
<script>
export default {
  data() {
    return {
      current: [],
    };
  },
  methods: {
    selectMenu(item) {
      this.current[0] = item.key;
    },
  },
};
</script>
