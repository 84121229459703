<template>
  <div>
    <a :href="excelFileUrl" target="_blank">
      <a-button icon="download" :size="size">Download Excel</a-button>
    </a>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      excelFileUrl:
        axios.defaults.baseURL + "/storage/importer_format/EventImport.xlsx", // Replace with the actual file path
      size: "default", // Size of the download button
    };
  },
  methods: {},
};
</script>

<style></style>
