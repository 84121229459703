<template>
  <div>
    <!-- Statistics Cards -->
    <div class="columns">
      <div class="column is-3">
        <div class="stat-card first">
          <p class="inner-text">
            कुल
            <br />
            डाउनलोड
          </p>
          <span v-if="isLoading"> <a-spin :indicator="indicator" /> </span>
          <span class="inner-text" style="float: right" v-else>
            {{ 277 + statCounts.ios + 1766 + statCounts.android }}
          </span>
        </div>
      </div>
      <div class="column is-3">
        <div class="stat-card fourth">
          <p class="inner-text">
            कुल
            <br />
            दर्ता
          </p>
          <span class="inner-text" style="float: right">
            {{ statCounts.total_registered }}</span
          >
        </div>
      </div>
      <div class="column is-3">
        <div class="stat-card second">
          <p class="inner-text">
            आईओएस <br />
            डाउनलोड
          </p>
          <span class="inner-text" style="float: right">{{
            277 + statCounts.ios
          }}</span>
        </div>
      </div>
      <div class="column is-3">
        <div class="stat-card third">
          <p class="inner-text">
            एन्ड्रोइड <br />
            डाउनलोड
          </p>
          <span class="inner-text" style="float: right">
            {{ 1766 + statCounts.android }}</span
          >
        </div>
      </div>
    </div>

    <!-- Graph Card -->
    <div class="card card-style" style="height: 400px; margin-top: 15px">
      <div class="card-content">
        <div id="chart">
          <!-- <h3 style="text-align: center">User Registrarion Trend</h3> -->
          <apexchart
            type="line"
            :options="chartOptions"
            :series="chartSeries"
            height="350"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ], // Time categories will go here
        },
        yaxis: {
          title: {
            text: "Count",
          },
        },
        legend: {
          position: "top",
        },
      },
      chartSeries: [
        {
          name: "Android",
          data: [], // Android user count data will go here
        },
        {
          name: "iOS",
          data: [], // iOS user count data will go here
        },
      ],
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      statCounts: [],
      graphData: {},
      isLoading: false,
    };
  },
  created() {
    this.getStatCounts();

    axios
      .get("api/ios-android-graph")
      .then((res) => {
        this.graphData = res.data;
      })
      .catch((err) => {
        console.log("err", err);
      });
  },
  watch: {
    graphData(newVal) {
      if (newVal) {
        this.createGraph(newVal);
      }
    },
  },

  methods: {
    getStatCounts() {
      this.isLoading = true;

      axios
        .get("api/dashboard-stats")
        .then((res) => {
          this.statCounts = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log("err", err);
          this.isLoading = false;
        });
    },
    createGraph(graphData) {
      this.chartSeries = [
        {
          name: "Android",
          data: graphData.androidData,
        },
        {
          name: "iOS",
          data: graphData.iOSData,
        },
      ];
    },
  },
};
</script>

<style scoped>
.card-style {
  border-radius: 29px;
  margin-top: 46px;
  height: 150px;
}
.stat-card {
  height: 116px;
  width: 100%;
  border-radius: 14px;
  padding: 15px;
}
.inner-text {
  font-size: 1rem;
  font-weight: bold;
}
.first {
  background: rgb(73, 231, 140);
  background: linear-gradient(
    345deg,
    rgba(73, 231, 140, 1) 13%,
    rgba(168, 255, 182, 1) 66%
  );
}
.second {
  background: rgb(233, 199, 80);
  background: linear-gradient(
    322deg,
    rgba(233, 199, 80, 1) 13%,
    rgba(246, 241, 121, 1) 66%
  );
}
.third {
  background: rgb(212, 158, 255);
  background: linear-gradient(
    259deg,
    rgba(212, 158, 255, 1) 13%,
    rgba(255, 214, 251, 1) 66%
  );
}
.fourth {
  background: linear-gradient(
    259deg,
    rgb(122 135 232) 13%,
    rgb(204 196 235) 66%
  );
}
</style>
