<template>
  <div>
    <div class="columns">
      <div class="column is-6 mobile-view">
        <div class="left">
          <div class="column is-12">
            <div class="calendar">
              <img src="@/../public/image/calendar.svg" alt="logo" />
            </div>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="login-form">
          <div class="form-wrapper">
            <div class="logo">
              <div class="column is-12" style="text-align: center">
                <img src="@/../public/image/logo/logo.svg" alt="logo" />
              </div>
              <div
                class="column is-12"
                style="text-align: center; visibility: hidden"
              >
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Labore molestiae nihil consectetur a ratione culpa iusto,
                  exercitationem ut! Natus ab, praesentium deserunt numquam sit
                  beatae eaque eligendi magnam amet quia?
                </p>
              </div>
            </div>
            <div class="form-field">
              <div class="columns">
                <div class="column is-2"></div>
                <div class="column is-8" style="margin-top: 30px">
                  <div class="field">
                    <p class="control has-icons-left has-icons-right">
                      <input
                        class="input login-input"
                        type="email"
                        placeholder="Email"
                        v-model="email"
                        v-on:keyup.enter="login()"
                      />
                      <span class="icon is-small is-left">
                        <i class="fas fa-envelope"></i>
                      </span>
                      <span
                        class="help is-danger"
                        :class="{ validate: isEmail }"
                        style="display: none"
                      >
                        Email is required.
                      </span>
                    </p>
                    <!-- <p class="help is-danger">This email is invalid</p> -->
                  </div>
                  <div class="field">
                    <p class="control has-icons-left">
                      <input
                        class="input login-input"
                        type="password"
                        placeholder="Password"
                        v-model="password"
                        @change="password"
                        v-on:keyup.enter="login()"
                      />
                      <span class="icon is-small is-left">
                        <i class="fas fa-lock"></i>
                      </span>
                      <span
                        class="help is-danger"
                        :class="{ validate: isPassword }"
                        style="display: none"
                      >
                        Password is required.
                      </span>
                    </p>
                  </div>
                  <div class="field">
                    <p class="control">
                      <button
                        class="button is-fullwidth is-rounded submit-btn"
                        :class="{ 'is-loading': loading }"
                        @click="login"
                      >
                        Login
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      isEmail: false,
      isPassword: false,
    };
  },
  watch: {
    // Checking if the email is empty. If it is empty, it will set the isEmail variable to true. If it is
    // not empty, it will set the isEmail variable to false.
    email(newEmail, oldEmail) {
      if (newEmail == "") {
        this.isEmail = true;
      } else {
        this.isEmail = false;
      }
    },

    // Checking if the password is empty. If it is empty, it will set the isPassword variable to true. If
    // it is
    // not empty, it will set the isPassword variable to false.
    password(newPassword, oldPassword) {
      if (newPassword == "") {
        this.isPassword = true;
      } else {
        this.isPassword = false;
      }
    },
  },

  methods: {
    // A shorthand for mapping the actions to the component.
    ...mapActions({
      signIn: "auth/signIn",
      attempt: "auth/attempt",
    }),

    // This is the login function. It is checking if the email and password are empty. If they are not
    // empty, it will call the signIn action from the auth module. If the signIn action is successful, it
    // will call the attempt action from the auth module. If the signIn action is not successful, it will
    // display an error message.
    login() {
      if (this.email == "") {
        this.isEmail = true;
      }
      if (this.password == "") {
        this.isPassword = true;
      }
      if (this.isEmail == false && this.isPassword == false) {
        this.loading = true;

        this.signIn({
          email: this.email,
          password: this.password,
        })
          .then((response) => {
            this.attempt(response);
            this.loading = false;
            this.$notify.success({
              position: "bottom right",
              msg: "Welcome Back!",
              timeout: 2000,
              styles: {
                minWidth: null, // default 250
                maxWidth: "calc(100% - 40px)", // default 350
                width: 250, // default null
                height: 70,
              },
            });
            this.$router.replace({
              name: "landingPage",
            });
          })
          .catch((err) => {
            this.loading = false;
            this.$notify.error({
              position: "bottom right",
              msg: "Could not login!",
              timeout: 2000,
              styles: {
                minWidth: null, // default 250
                maxWidth: "calc(100% - 40px)", // default 350
                width: 250, // default null
                height: 70,
              },
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.left {
  height: 100vh;
  width: 100%;
  background: #254594;
}
.calendar {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-input {
  border-radius: 20px;
}
.submit-btn {
  background-color: #254594;
  color: #fff;
}
.submit-btn:hover {
  background-color: #fff;
  color: #254594;
  border: 1px solid #254594;
  transition: 0.5s;
}
.validate {
  display: block !important;
}
@media screen and (max-width: 768px) {
  .mobile-view {
    display: none;
  }
  .form-field {
    padding: 20px;
  }
}
</style>
