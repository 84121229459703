<template>
  <div>
    <div class="mobile-nav">
      <mobilenav />
    </div>
    <div class="columns">
      <div class="column side-nav">
        <sidebar />
      </div>
      <div class="column is-10">
        <navbar />
        <pdf />
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar/sidebar.vue";
import navbar from "@/components/Navbar/navbar.vue";
import etable from "@/components/EventTable/Table.vue";
import pdf from "@/components/Video/index.vue";
import mobilenav from "@/components/Navbar/mobilenav.vue";

export default {
  name: "LandingPage",
  components: {
    sidebar,
    navbar,
    etable,
    pdf,
    mobilenav,
  },
};
</script>

<style scoped>
.mobile-nav {
  display: none;
}
@media screen and (max-width: 768px) {
  .mobile-nav {
    display: block;
  }
  .side-nav {
    display: none;
  }
}
</style>
