<template>
  <div>
    <div class="columns">
      <div class="column">
        <sidebar />
      </div>
      <div class="column is-10">
        <navbar />
        <notification />
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar/sidebar.vue";
import navbar from "@/components/Navbar/navbar.vue";
import notification from "@/components/Notification/index.vue"

export default {
  name: "Pushnotification",
  components: {
    sidebar,
    navbar,
    notification
  },
};
</script>