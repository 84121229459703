<template>
  <div style="margin-top: 35px">
    <div class="columns">
      <div class="column is-11 table-headers">
        <h5 class="table-title">इभेन्ट</h5>
        <div class="table-btn">
          <div style="margin-right: 10px">
            <excelFormater />
          </div>
          <excelImporter />
        </div>
      </div>
    </div>
    <editEvent ref="editmodel" />
    <div class="columns">
      <div class="column is-11">
        <a-table :columns="columns" :data-source="data" :scroll="{ y: 530 }">
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              placeholder="Year-Month-Date"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            >
              Search
            </a-button>
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
            >
              Reset
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>
              {{ text }}
            </template>
          </template>
          <a slot="operation" slot-scope="text">
            <a-icon
              type="eye"
              theme="twoTone"
              two-tone-color="#52c41a"
              style="font-size: 1.2rem"
              @click="model(text)"
            />
            <a-divider type="vertical" />
            <a-icon type="edit" style="font-size: 1.2rem" @click="edit(text)" />
            <a-divider type="vertical" />
            <a-popconfirm
              title="Are you sure？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="confirm(text.id)"
            >
              <a-icon
                type="delete"
                style="font-size: 1.2rem"
                :style="{ color: 'hotpink' }"
              />
            </a-popconfirm>
          </a>
        </a-table>
      </div>
    </div>
    <showEvent ref="show" />
  </div>
</template>
<script>
import axios from "axios";
import editEvent from "@/components/EventForm/editform.vue";
import showEvent from "./EventDetail.vue";
import excelImporter from "./Importer.vue";
import excelFormater from "./ImporterFormat.vue";
const columns = [
  {
    title: "न.",
    dataIndex: "sn",
    // key: "sn",
    // width: "15%",
    defaultSortOrder: "ascend",
    sorter: (a, b) => a.sn - b.sn,
    sortDirections: ["descend", "ascend"],
    width: 100,
  },
  {
    title: "मिति (BS)",
    dataIndex: "date_np",
    key: "date_np",
    width: 200,
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender",
    },
    // defaultSortOrder: "ascend",
    // sortDirections: ["descend", "ascend"],
    // sorter: (a, b) => {
    //   const dateA = new Date(a.date_np);
    //   const dateB = new Date(b.date_np);
    //   return dateA - dateB;
    // },
    onFilter: (value, record) =>
      record.date_np.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    },
  },
  {
    title: "मिति (AD)",
    dataIndex: "date_en",
    key: "date_en",
    width: 200,
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender",
    },
    onFilter: (value, record) =>
      record.date_en.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    },
  },
  {
    title: "इभेन्ट",
    dataIndex: "event",
    key: "event",
    // width:"50"
    ellipsis: true,
  },
  // {
  //   title: "रंग",
  //   dataIndex: "color",
  //   key: "color",
  //   scopedSlots: { customRender: "color" },
  // },
  {
    title: "कार्य",
    key: "operation",
    scopedSlots: { customRender: "operation" },
  },
];

const data = [];

export default {
  data() {
    return {
      data,
      columns,
      searchInput: null,
      searchText: "",
    };
  },
  components: {
    editEvent,
    showEvent,
    excelImporter,
    excelFormater,
  },
  created() {
    this.fetchEvent();
  },
  methods: {
    // Calling the showModal method of the showEvent component.
    model(e) {
      this.$refs.show.showModal(e);
    },
    // Fetching the data from the database and assigning it to the data variable.
    fetchEvent() {
      axios.get("/api/events", {}).then((res) => {
        this.data = res.data.data;
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].sn = i + 1;
        }
      });
    },
    // This method is used to delete the event.
    confirm(id) {
      axios
        .get("/api/event/destroy/" + id)
        .then((res) => {
          this.fetchEvent();
          this.$notify.success({
            position: "bottom right",
            msg: "Event deleted successfully!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
        })
        .catch((err) => {
          this.$notify.error({
            position: "bottom right",
            msg: "Event could not be deleted!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
        });
    },
    // This method is used to search the data from the table.
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    // This method is used to reset the search.
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    // This method is used to call the showModal method of the editEvent component.
    edit(e) {
      this.$refs.editmodel.showModal(e);
    },
  },
};
</script>

<style scoped>
.table-title {
  font-size: 1.5rem;
  margin-bottom: 0px;
  color: #254594;
  margin-bottom: 10px;
}
.table-headers {
  display: flex;
  justify-content: space-between;
}

.table-headers .table-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
