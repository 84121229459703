<template>
  <div>
    <a-button type="primary" @click="showModal">
      Upload File
    </a-button>
    <a-modal v-model="visible" title="Add File" centered>
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          Return
        </a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
          Upload file
        </a-button>
      </template>
      <div class="field">
        <div class="label">फाइल</div>
        <div class="control">
          <div class="file has-name">
            <label class="file-label">
              <input
                  class="file-input"
                  type="file"
                  name="resume"
                  ref="file"
                  @change="upload"
                  accept="application/vnd.ms-excel , .csv , .xlsx"
              />
              <span
                  class="file-cta"
                  style="
                        border-top-left-radius: 15px;
                        border-bottom-left-radius: 15px;
                      "
              >
                      <span class="file-icon">
                        <i class="fas fa-upload"></i>
                      </span>
                      <span class="file-label"> Choose a file </span>
                    </span>
              <span
                  class="file-name"
                  style="
                        min-width:300px;
                        border-top-right-radius: 15px;
                        border-bottom-right-radius: 15px;
                      "
              >
                      {{ fileName }}
                    </span>
            </label>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      visible: false,
      fileName: "",
      excel: null,
      loading: false,
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      const fd = new FormData();
      this.loading = true;
      fd.append("file", this.excel);
      axios.post("api/holiday-import", fd).then(res=>{
        this.loading = false;
        this.$notify.success({
          position: "bottom right",
          msg: "File uploaded successfully!",
          timeout: 2000,
          styles: {
            minWidth: null, // default 250
            maxWidth: "calc(100% - 40px)", // default 350
            width: 250, // default null
            height: 70,
          },
        });
        this.handleCancel()
      }).catch(err=>{
        this.loading = false;
        this.$notify.error({
          position: "bottom right",
          msg: "File could not be deleted!",
          timeout: 2000,
          styles: {
            minWidth: null, // default 250
            maxWidth: "calc(100% - 40px)", // default 350
            width: 250, // default null
            height: 70,
          },
        });
      })



      // axios.post()
    },
    handleCancel()
    {
      this.fileName = "";
      this.excel = null;
      this.visible = false;
    }
    ,
    // This method is called when the user selects a file. It sets the name of the file to `fileName` and
    // the file itself to `form.pdf`.
    upload()
    {
      this.fileName = this.$refs.file.files[0].name;
      this.excel = this.$refs.file.files[0];
    }
    ,
  },
};
</script>
