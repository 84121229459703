<template>
  <div>
    <a-modal
      v-model="visible"
      title="इभेन्ट"
      @ok="handleOk"
      :width="800"
      centered
    >
      <template slot="footer">
        <button
          class="button is-danger is-outlined"
          style="border-radius: 34px; height: 40px"
          @click="clearForm()"
        >
          रिसेट
        </button>
        <button
          class="button submit sbutton"
          :class="{ 'is-loading': loading }"
          @click="saveEvent"
          style="margin-top: 0px"
        >
          सेभ गर्नुहोस
        </button>
      </template>
      <div class="columns">
        <div class="column is-12">
          <!-- form -->
          <section>
            <div class="field">
              <label class="label">मिति छान्नुहोस्</label>

              <div class="control">
                <VNepaliDatePicker
                  calenderType="English"
                  :placeholder="dateTitle"
                  format="YYYY-MM-DD"
                  classValue="nepalidatepicker"
                  value
                  class="datepicker"
                  @change="selectDate"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">इभेन्ट विवरण</label>
              <div class="control">
                <textarea
                  class="textarea detail"
                  placeholder="इभेन्ट विवरण"
                  v-model="form.event"
                ></textarea>
              </div>
            </div>
            <div class="field">
              <div class="label">श्रोत</div>
              <div class="control">
                <input
                  class="input"
                  v-model="form.source"
                  type="url"
                  placeholder="श्रोत"
                  style="border-radius: 15px"
                />
              </div>
            </div>
            <div class="field">
              <div class="label">फाइल</div>
              <div class="control">
                <div class="file has-name">
                  <label class="file-label">
                    <input
                      class="file-input"
                      type="file"
                      name="resume"
                      ref="file"
                      @change="upload"
                    />
                    <span
                      class="file-cta"
                      style="
                        border-top-left-radius: 15px;
                        border-bottom-left-radius: 15px;
                      "
                    >
                      <span class="file-icon">
                        <i class="fas fa-upload"></i>
                      </span>
                      <span class="file-label"> Choose a file </span>
                    </span>
                    <span
                      class="file-name"
                      style="
                        min-width: 599px;
                        border-top-right-radius: 15px;
                        border-bottom-right-radius: 15px;
                      "
                    >
                      {{ fileName }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="label">भिडियो</div>
              <div class="control">
                <input
                  class="input"
                  v-model="form.video"
                  type="url"
                  placeholder="भिडियो"
                  style="border-radius: 15px"
                />
              </div>
            </div>
            <div class="columns">
              <div class="column is-6">
                <div class="field">
                  <div class="control">
                    <label class="checkbox">
                      <input
                        type="checkbox"
                        @change="selectIcon($event)"
                        :checked="form.has_icon == 1 ? true : false"
                      />
                      आइकन देखाउनुहोस्
                    </label>
                  </div>
                </div>
                <div class="control">
                  <label class="radio">
                    <input type="radio" v-model="color" value="1" />
                    <span class="colorBox" style="background-color: #e17efc">
                    </span>
                  </label>
                  <label class="radio">
                    <input type="radio" v-model="color" value="2" />
                    <span
                      class="colorBox"
                      style="background-color: #ee75a8"
                    ></span>
                  </label>
                  <label class="radio">
                    <input type="radio" v-model="color" value="3" />
                    <span
                      class="colorBox"
                      style="background-color: #3dd9af"
                    ></span>
                  </label>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <div class="control">
                    <label class="checkbox">
                      <input
                        type="checkbox"
                        @change="sendNotification($event)"
                      />
                      सूचना पठाउनुहोस्
                    </label>
                  </div>
                </div>
                <div v-if="notification" class="control notification-box">
                  <a-tree-select
                    v-model="value"
                    style="width: 100%"
                    :tree-data="treeData"
                    tree-checkable
                    :show-checked-strategy="SHOW_PARENT"
                    placeholder="प्रयोगकर्ता चयन गर्नुहोस्"
                  />
                  <label class="radio">
                    <input type="radio" v-model="notificationTime" value="1" />
                    एक दिन अघि
                  </label>
                  <br />
                  <label class="radio">
                    <input type="radio" v-model="notificationTime" value="7" />
                    एक हप्ता अघि
                  </label>
                  <br />
                  <label class="radio">
                    <input type="radio" v-model="notificationTime" value="0" />
                    उही दिन
                  </label>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import VNepaliDatePicker from "v-nepalidatepicker";
import axios from "axios";
import { TreeSelect } from "ant-design-vue";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const treeData = [
  {
    title: "अध्यक्ष",
    value: "अध्यक्ष",
    key: "अध्यक्ष",
  },
  {
    title: "उपाध्यक्ष",
    value: "उपाध्यक्ष",
    key: "उपाध्यक्ष",
  },
  {
    title: "वडा सदस्य",
    value: "वडा सदस्य",
    key: "वडा सदस्य",
  },
  {
    title: "मेयर",
    value: "मेयर",
    key: "मेयर",
  },
  {
    title: "उपमेयर",
    value: "उपमेयर",
    key: "उपमेयर",
  },
  {
    title: "वडा अध्यक्ष",
    value: "वडा अध्यक्ष",
    key: "वडा अध्यक्ष",
  },
  {
    title: "महिला वडा सदस्य",
    value: "महिला वडा सदस्य",
    key: "महिला वडा सदस्य",
  },
  {
    title: "दलित महिला सदस्य",
    value: "दलित महिला सदस्य",
    key: "दलित महिला सदस्य",
  },
  {
    title: "कार्यपालिका सदस्य",
    value: "कार्यपालिका सदस्य",
    key: "कार्यपालिका सदस्य",
  },
  {
    title: "प्रमुख प्रशासकीय अधिकृत",
    value: "प्रमुख प्रशासकीय अधिकृत",
    key: "प्रमुख प्रशासकीय अधिकृत",
  },
  {
    title: "नागरिक",
    value: "नागरिक",
    key: "नागरिक",
  },
  {
    title: "अन्य",
    value: "अन्य",
    key: "अन्य",
  },
];
export default {
  data() {
    return {
      visible: false,
      loading: false,
      fileName: "",
      color: "",
      notificationTime: "",
      form: {
        has_icon: 0,
        pdf: null,
        video: "",
        source: "",
        event: "",
      },
      eventId: null,
      dateTitle: "मिति छान्नुहोस्",
      notification: false,
      value: [],
      roles: [],
      treeData,
      SHOW_PARENT,
    };
  },
  components: {
    VNepaliDatePicker,
  },
  methods: {
    // A method that is called when the checkbox is clicked. It checks if the checkbox is checked or not
    // and sets the value of `notification` accordingly.
    sendNotification(e) {
      e.target.checked == true
        ? (this.notification = true)
        : (this.notification = false);
    },
    // The above code is showing the modal when the user clicks on the event.
    showModal(e) {
      this.form = e;
      this.dateTitle = this.form.date_np;
      this.fileName = this.form.pdf;
      this.eventId = this.form.id;
      if (this.form.color == "E17EFC") {
        this.color = 1;
      } else if (this.form.color == "EE75A8") {
        this.color = 2;
      } else if (this.form.color == "3DD9AF") {
        this.color = 3;
      } else {
        this.color = "";
      }
      this.visible = true;
    },
    // Closing the modal.
    handleOk(e) {
      this.visible = false;
    },
    // This method is called when the user selects a file. It sets the name of the file to `fileName` and
    // the file itself to `form.pdf`.
    upload() {
      this.fileName = this.$refs.file.files[0].name;
      this.form.pdf = this.$refs.file.files[0];
    },
    // Clearing the form and the file name.
    clearForm() {
      this.form = {};
      this.fileName = "";
    },
    // The above code is a method that is called when the user selects a date from the datepicker.
    selectDate(date) {
      this.form.date_np = date;
    },
    // A method that is called when the checkbox is clicked. It is checking if the checkbox is checked or
    // not. If it is checked, it sets the value of the has_icon property to 1. If it is not checked, it
    // sets the value of the has_icon property to 0.
    selectIcon(e) {
      e.target.checked == true
        ? (this.form.has_icon = 1)
        : (this.form.has_icon = 0);
    },
    // The above code is used to update the event.
    saveEvent() {
      this.loading = true;
      this.selectColor(this.color);
      const fd = new FormData();
      fd.append("date_np", this.form.date_np);
      fd.append("event", this.form.event);
      fd.append("video", this.form.video);
      fd.append("source", this.form.source);
      if (this.form.pdf !== null) {
        fd.append("pdf", this.form.pdf);
      }
      if (this.notification) {
        fd.append("days", this.notificationTime);
        this.value.forEach((el) => {
          this.roles.push({
            role: el,
          });
        });
        fd.append("roles", JSON.stringify(this.roles));
      }
      fd.append("has_icon", this.form.has_icon);
      fd.append("color", this.form.color);
      fd.append("id", this.eventId);
      axios
        .post("/api/event/update/" + this.form.id, fd)
        .then((res) => {
          this.loading = false;
          this.clearForm();
          this.$notify.success({
            position: "bottom right",
            msg: "Event updated successfully!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
          this.visible = false;
          // this.$router.push("/event");
        })
        .catch((err) => {
          this.$notify.error({
            position: "bottom right",
            msg: "Event could not be updated!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
          this.loading = false;
          this.visible = false;
        });
    },
    // The above code is a method that is called when a user clicks on a color. The method takes in an
    // event parameter. The event parameter is the color that the user clicked on. The method then sets the
    // color property of the form object to the color that the user clicked on.
    selectColor(e) {
      if (e == 1) {
        this.form.color = "E17EFC";
      } else if (e == 2) {
        this.form.color = "EE75A8";
      } else if (e == 3) {
        this.form.color = "3DD9AF";
      } else {
        this.form.color = "";
      }
    },
    selectNotification(e) {},
  },
};
</script>

<style scoped></style>
