<template>
  <div>
    <h4 class="title">video</h4>
    <div class="field">
      <label class="label"> फाइलको नाम</label>
      <div class="control">
        <input
          class="input"
          v-model="form.name"
          type="text"
          placeholder="फाइलको नाम"
        />
      </div>
    </div>
    <div class="field">
      <div class="label">भिडियो</div>
      <div class="control">
        <input
          class="input"
          v-model="form.video"
          type="url"
          placeholder="भिडियो लिङ्क"
        />
      </div>
    </div>
    <div class="field">
      <div class="label">फोटो</div>
      <div class="control">
        <div class="file has-name">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              name="resume"
              ref="file"
              @change="upload"
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label"> Choose a file </span>
            </span>
            <span class="file-name" style="min-width: 230px">
              {{ fileName }}
            </span>
          </label>
        </div>
      </div>
    </div>
    <button
      class="button submit pbutton"
      :class="{ 'is-loading': loading }"
      @click="saveVideo"
      v-if="edit == false"
    >
      सेभ गर्नुहोस
    </button>
    <button
      class="button submit pbutton"
      :class="{ 'is-loading': loading }"
      @click="updateVideo"
      v-else
    >
      अपडेट गर्नुहोस्
    </button>
  </div>
</template>

<script>
import axios from "axios";
import { bus } from "../../main";
export default {
  data() {
    return {
      form: {
        image: null,
      },
      loading: false,
      fileName: "",
      edit: false,
    };
  },
  created() {
    bus.$on("edit-video", (e) => {
      this.edit = true;
      this.form = e;
      this.fileName = this.image;
    });
  },
  methods: {
    upload() {
      this.fileName = this.$refs.file.files[0].name;
      this.form.image = this.$refs.file.files[0];
    },
    clearForm() {
      this.form = {};
      this.fileName = ""
    },
    updateVideo() {
      this.loading = true;
      const fd = new FormData();
      fd.append("name", this.form.name);
      fd.append("video", this.form.video);
      if (this.form.image !== null) {
        fd.append("image", this.form.image);
      }
      axios
        .post("api/video/update/" + this.form.id, fd)
        .then((res) => {
          this.loading = false;
          this.$notify.success({
            position: "bottom right",
            msg: "Video updated successfully!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
          this.form = {};
          this.clearForm()
          bus.$emit("store", true);
        })
        .catch((err) => {
          this.loading = false;
          this.$notify.error({
            position: "bottom right",
            msg: "Video could not be updated!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
        });
    },
    saveVideo() {
      this.loading = true;
      const fd = new FormData();
      fd.append("name", this.form.name);
      fd.append("video", this.form.video);
      if (this.form.image !== null) {
        fd.append("image", this.form.image);
      }
      axios
        .post("/api/store-calendar-videos", fd)
        .then((res) => {
          this.loading = false;
          this.$notify.success({
            position: "bottom right",
            msg: "Video added successfully!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
          this.form = {};
          this.clearForm()
          bus.$emit("store", true);
        })
        .catch((err) => {
          this.loading = false;
          this.$notify.error({
            position: "bottom right",
            msg: "Video could not be stored!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
        });
    },
  },
};
</script>

<style scoped>
.label {
  color: #6a6a6a;
  font-weight: 400;
}
.title {
  font-size: 1.5rem;
  margin-bottom: 0px;
  color: #254594;
  margin-bottom: 10px;
}
.pbutton {
  background: #254594;
  color: #fff;
  border-radius: 34px;
  width: 153px;
  height: 40px;
  margin-top: 30px;
}
.pbutton:hover {
  color: #fff;
}
.pbutton:focus {
  color: #fff;
}
</style>
