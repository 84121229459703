<template>
    <div style="margin-top:40px;">
        <div class="columns">
            <div class="column is-4">
                <notificationForm />
            </div>
            <div class="column is-0"></div>
            <div class="column is-7">
                <!-- <notificationTable /> -->
            </div>
        </div>
    </div>
</template>

<script>
import notificationForm from "@/components/Notification/notificationForm.vue"
// import notificationTable from "@/components/Notification/notificationTable.vue"
export default{
    components:{
        notificationForm,
        // notificationTable
    }
}
</script>
