<template>
    <div style="margin-top:40px;">
        <div class="columns">
            <div class="column is-4">
                <tithiform />
            </div>
            <div class="column is-0"></div>
            <div class="column is-7">
                <fileUploader  style="float:right"/>
            </div>
        </div>
    </div>
</template>

<script>
import tithiform from "@/components/Holliday/tithiform.vue"
import fileUploader from "@/components/Holliday/fileUploader.vue";
export default{
    components:{
        tithiform,
         fileUploader
    }
}
</script>
