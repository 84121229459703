<template>
  <div>
    <h4 class="title">Notification</h4>
    <div class="field">
      <label class="label">प्रयोगकर्ता</label>
      <a-tree-select
        v-model="value"
        style="width: 100%"
        :tree-data="treeData"
        tree-checkable
        :show-checked-strategy="SHOW_PARENT"
        placeholder="प्रयोगकर्ता चयन गर्नुहोस्"
      />
    </div>
    <div class="field">
      <label class="label"> विषय</label>
      <div class="control">
        <input
          class="input"
          v-model="form.title"
          type="text"
          placeholder="फाइलको नाम"
        />
      </div>
    </div>
    <div class="field">
      <div class="label">फोटो</div>
      <div class="control">
        <div class="file has-name">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              name="resume"
              ref="file"
              @change="upload"
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label"> Choose a file </span>
            </span>
            <span class="file-name" style="min-width: 230px">
              {{ fileName }}
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">सन्देश</label>
      <div class="control">
        <textarea
          class="textarea"
          placeholder="सन्देश लेख्नुहोस्"
          v-model="form.message"
        ></textarea>
      </div>
    </div>
    <button
      class="button submit pbutton"
      :class="{ 'is-loading': loading }"
      @click="saveVideo"
    >
      सूचना पठाउनुहोस्
    </button>
  </div>
</template>

<script>
import axios from "axios";
import { bus } from "../../main";
import { TreeSelect } from "ant-design-vue";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const treeData = [
  {
    title: "अध्यक्ष",
    value: "अध्यक्ष",
    key: "अध्यक्ष",
  },
  {
    title: "उपाध्यक्ष",
    value: "उपाध्यक्ष",
    key: "उपाध्यक्ष",
  },
  {
    title: "वडा सदस्य",
    value: "वडा सदस्य",
    key: "वडा सदस्य",
  },
  {
    title: "मेयर",
    value: "मेयर",
    key: "मेयर",
  },
  {
    title: "उपमेयर",
    value: "उपमेयर",
    key: "उपमेयर",
  },
  {
    title: "वडा अध्यक्ष",
    value: "वडा अध्यक्ष",
    key: "वडा अध्यक्ष",
  },
  {
    title: "महिला वडा सदस्य",
    value: "महिला वडा सदस्य",
    key: "महिला वडा सदस्य",
  },
  {
    title: "दलित महिला सदस्य",
    value: "दलित महिला सदस्य",
    key: "दलित महिला सदस्य",
  },
  {
    title: "कार्यपालिका सदस्य",
    value: "कार्यपालिका सदस्य",
    key: "कार्यपालिका सदस्य",
  },
  {
    title: "प्रमुख प्रशासकीय अधिकृत",
    value: "प्रमुख प्रशासकीय अधिकृत",
    key: "प्रमुख प्रशासकीय अधिकृत",
  },
  {
    title: "नागरिक",
    value: "नागरिक",
    key: "नागरिक",
  },
  {
    title: "अन्य",
    value: "अन्य",
    key: "अन्य",
  },
];
export default {
  data() {
    return {
      form: {
        title: "",
        message: "",
        image: null,
      },
      loading: false,
      fileName: "",
      edit: false,
      value: [],
      roles: [],
      treeData,
      SHOW_PARENT,
    };
  },
  created() {
    bus.$on("edit-video", (e) => {
      this.edit = true;
      this.form = e;
      this.fileName = this.image;
    });
  },
  methods: {
    upload() {
      this.fileName = this.$refs.file.files[0].name;
      this.form.image = this.$refs.file.files[0];
    },
    clearForm() {
      this.form = {};
      this.fileName = "";
    },
    updateVideo() {
      this.loading = true;
      const fd = new FormData();
      fd.append("name", this.form.name);
      fd.append("video", this.form.video);
      if (this.form.image !== null) {
        fd.append("image", this.form.image);
      }
      axios
        .post("api/video/update/" + this.form.id, fd)
        .then((res) => {
          this.loading = false;
          this.$notify.success({
            position: "bottom right",
            msg: "Video updated successfully!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
          this.form = {};
          this.clearForm();
          bus.$emit("store", true);
        })
        .catch((err) => {
          this.loading = false;
          this.$notify.error({
            position: "bottom right",
            msg: "Video could not be updated!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
        });
    },
    saveVideo() {
      this.loading = true;
      const fd = new FormData();
      fd.append("title", this.form.title);
      fd.append("message" , this.form.message);
      if (this.form.image !== null) {
        fd.append("image", this.form.image);
      }
      this.value.forEach((el) => {
        this.roles.push({
          role: el,
        });
      });
      fd.append("roles", JSON.stringify(this.roles));
      axios
        .post("/api/send-notification", fd)
        .then((res) => {
           this.loading = false;
          this.$notify.success({
            position: "bottom right",
            msg: "Notification sent successfully!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
          this.form = {};
          this.clearForm();
          bus.$emit("store", true);
        })
        .catch((err) => {
          this.loading = false;
          this.$notify.error({
            position: "bottom right",
            msg: "Notification could not be sent!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
        });
    },
  },
};
</script>

<style scoped>
.label {
  color: #6a6a6a;
  font-weight: 400;
}
.title {
  font-size: 1.5rem;
  margin-bottom: 0px;
  color: #254594;
  margin-bottom: 10px;
}
.pbutton {
  background: #254594;
  color: #fff;
  border-radius: 34px;
  width: 153px;
  height: 40px;
  margin-top: 30px;
}
.pbutton:hover {
  color: #fff;
}
.pbutton:focus {
  color: #fff;
}
</style>
