<template>
  <div>
    <!-- <div>
      <h5 class="event">नयाँ इभेन्ट</h5>
      <h5 class="event">Apps Download and Registration Insights</h5>
    </div> -->
    <div class="columns">
      <div class="column is-5">
        <!-- form -->
        <h5 class="event">नयाँ इभेन्ट</h5>
        <section style="margin-top: 20px">
          <div class="field">
            <label class="label">मिति छान्नुहोस्</label>

            <div class="control">
              <VNepaliDatePicker
                calenderType="English"
                placeholder="मिति छान्नुहोस्"
                format="YYYY-MM-DD"
                classValue="nepalidatepicker"
                value
                class="datepicker"
                @change="selectDate"
              />
            </div>
            <p v-if="validationErrors.date_np" class="help is-danger">
              मिति फिल्ड आवश्यक छ।
            </p>
          </div>
          <div class="field">
            <label class="label">इभेन्ट विवरण</label>
            <div class="control">
              <textarea
                class="textarea detail"
                placeholder="इभेन्ट विवरण"
                @input="validationErrors.event = false"
                v-model="form.event"
              ></textarea>
            </div>
            <p v-if="validationErrors.event" class="help is-danger">
              इभेन्ट फिल्ड आवश्यक छ।
            </p>
          </div>
          <div class="field">
            <div class="label">श्रोत</div>
            <div class="control">
              <input
                class="input"
                v-model="form.source"
                type="url"
                placeholder="https://source-url.com"
                style="border-radius: 15px"
              />
            </div>
          </div>
          <div class="field">
            <div class="label">फाइल</div>
            <div class="control">
              <div class="file has-name">
                <label class="file-label">
                  <input
                    class="file-input"
                    type="file"
                    name="resume"
                    ref="file"
                    @change="upload"
                  />
                  <span
                    class="file-cta"
                    style="
                      border-top-left-radius: 15px;
                      border-bottom-left-radius: 15px;
                    "
                  >
                    <span class="file-icon">
                      <i class="fas fa-upload"></i>
                    </span>
                    <span class="file-label"> Choose a file </span>
                  </span>
                  <span
                    class="file-name"
                    style="
                      min-width: 330px;
                      border-top-right-radius: 15px;
                      border-bottom-right-radius: 15px;
                    "
                  >
                    {{ fileName }}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="label">भिडियो</div>
            <div class="control">
              <input
                class="input"
                v-model="form.video"
                type="url"
                placeholder="https://example.com"
                style="border-radius: 15px"
              />
            </div>
          </div>
          <div class="field">
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" @change="selectIcon($event)" />
                आइकन देखाउनुहोस्
              </label>
            </div>
            <!-- <p v-if="validationErrors.has_icon" class="help is-danger">
              कृपया आइकन चेकबक्समा टिक गर्नुहोस्।
            </p> -->
          </div>
          <!-- <div class="columns">
            <div class="column is-3">
              <label class="checkbox">
                <input type="checkbox" />
                <span
                  style="
                    height: 15px;
                    width: 15px;
                    background-color: #e17efc;
                    position: absolute;
                    margin-left: 10px;
                  "
                ></span>
              </label>
            </div>
            <div class="column is-3">
              <label class="checkbox">
                <input type="checkbox" />
                <span
                  style="
                    height: 15px;
                    width: 15px;
                    background-color: #ee75a8;
                    position: absolute;
                    margin-left: 10px;
                  "
                ></span>
              </label>
            </div>
            <div class="column is-3">
              <label class="checkbox">
                <input type="checkbox" />
                <span
                  style="
                    height: 15px;
                    width: 15px;
                    background-color: #3dd9af;
                    position: absolute;
                    margin-left: 10px;
                  "
                ></span>
              </label>
            </div>
          </div> -->
          <div class="control">
            <label class="radio">
              <input type="radio" v-model="color" value="1" />
              <span class="colorBox" style="background-color: #e17efc"> </span>
            </label>
            <label class="radio">
              <input type="radio" v-model="color" value="2" />
              <span class="colorBox" style="background-color: #ee75a8"></span>
            </label>
            <label class="radio">
              <input type="radio" v-model="color" value="3" />
              <span class="colorBox" style="background-color: #3dd9af"></span>
            </label>
          </div>
          <button
            class="button submit sbutton"
            :class="{ 'is-loading': loading }"
            @click="saveEvent"
          >
            सेभ गर्नुहोस
          </button>
        </section>
      </div>
      <div class="column is-0"></div>
      <div class="column is-6">
        <h5 class="event">Apps Download and Registration Insights</h5>

        <stat style="margin-top: 20px" />
      </div>
    </div>
  </div>
</template>

<script>
import VNepaliDatePicker from "v-nepalidatepicker";
import stat from "@/components/Stat/stat.vue";
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      fileName: "",
      color: "",
      form: {
        has_icon: 0,
        pdf: null,
        video: "",
        source: "",
        event: "",
      },
      validationErrors: {
        event: false,
        // has_icon: false,
        date_np: false,
      },
    };
  },
  components: {
    VNepaliDatePicker,
    stat,
  },
  methods: {
    // Getting the file name and the file itself.
    upload() {
      this.fileName = this.$refs.file.files[0].name;
      this.form.pdf = this.$refs.file.files[0];
    },
    // Clearing the form after the event is created.
    clearForm() {
      this.form = {};
      this.fileName = "";
    },
    // Getting the date from the datepicker and assigning it to the form.
    selectDate(date) {
      this.form.date_np = date;
      this.validationErrors.date_np = false; // Reset validation error
    },
    // Checking if the checkbox is checked or not. If it is checked, it will assign 1 to the form.has_icon.
    // If it is not checked, it will assign 0 to the form.has_icon.
    selectIcon(e) {
      e.target.checked == true
        ? (this.form.has_icon = 1)
        : (this.form.has_icon = 0);

      // Clear icon validation error when the user interacts with the field
      // this.validationErrors.has_icon = false;
    },
    // The above code is used to create a new event.
    saveEvent() {
      // Reset validation errors
      this.validationErrors.event = false;
      this.validationErrors.has_icon = false;

      // Validate required fields
      if (!this.form.event) {
        this.validationErrors.event = true;
      }
      // if (!this.form.has_icon) {
      //   this.validationErrors.has_icon = true;
      // }
      if (!this.form.date_np) {
        this.validationErrors.date_np = true;
      }

      // Check if any validation errors are present
      if (Object.values(this.validationErrors).some((error) => error)) {
        return; // Stop if there are validation errors
      }
      this.loading = true;
      this.selectColor(this.color);
      const fd = new FormData();
      fd.append("date_np", this.form.date_np);
      fd.append("event", this.form.event);
      fd.append("video", this.form.video);
      fd.append("source", this.form.source);
      if (this.form.pdf !== null) {
        fd.append("pdf", this.form.pdf);
      }
      fd.append("has_icon", this.form.has_icon);
      fd.append("color", this.form.color);
      axios
        .post("/api/event/store", fd)
        .then((res) => {
          this.loading = false;
          this.clearForm();
          this.$notify.success({
            position: "bottom right",
            msg: "Event created successfully!",
            timeout: 2000,
            styles: {
              minWidth: null, // default 250
              maxWidth: "calc(100% - 40px)", // default 350
              width: 250, // default null
              height: 70,
            },
          });
          // this.$router.push("/event");
        })
        .catch((err) => {
          let errorMsg = Object.keys(err.response.data.message);
          errorMsg.forEach((el) => {
            if (el == "event") {
              this.$notify.error({
                position: "bottom right",
                msg: "Event field is required!",
                timeout: 2000,
                styles: {
                  minWidth: null, // default 250
                  maxWidth: "calc(100% - 40px)", // default 350
                  width: 250, // default null
                  height: 70,
                },
              });
            } else {
              this.$notify.error({
                position: "bottom right",
                msg: "Event could not be create!",
                timeout: 2000,
                styles: {
                  minWidth: null, // default 250
                  maxWidth: "calc(100% - 40px)", // default 350
                  width: 250, // default null
                  height: 70,
                },
              });
              s;
            }
          });
          this.loading = false;
        });
    },
    // Assigning the color to the form.color.
    selectColor(e) {
      if (e == 1) {
        this.form.color = "E17EFC";
      } else if (e == 2) {
        this.form.color = "EE75A8";
      } else if (e == 3) {
        this.form.color = "3DD9AF";
      } else {
        this.form.color = "";
      }
    },
  },
};
</script>

<style>
.event {
  font-size: 1.5rem;
  margin-bottom: 0px;
  color: #254594;
}
/* .nepalidate {
  width: 100%;
  max-width: 100%;
  border-radius: 15px;
  height: 42px;
  border-color: #bdbdbd;
} */
.nepalidatepicker {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  transition: all 0.3s;
}

.nepalidatepicker:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.label {
  color: #6a6a6a;
  font-weight: 400;
}
.sbutton {
  background: #254594;
  color: #fff;
  border-radius: 34px;
  width: 153px;
  height: 40px;
  margin-top: 20px;
}
.sbutton:hover {
  color: #fff;
}
.sbutton:focus {
  color: #fff;
}
.detail {
  border-radius: 21px;
}
.colorBox {
  height: 15px;
  width: 15px;
  background-color: black;
  position: absolute;
  top: 2px;
  left: 15px;
}
.radio {
  margin-right: 20px;
}
</style>
