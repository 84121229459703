<template>
  <div>
    <div class="mobile-nav">
      <mobilenav />
    </div>
    <div class="columns">
      <div class="column side-nav">
        <sidebar />
      </div>
      <div class="column is-10">
        <navbar class="desktop-nav" />
        <!-- <div class="column is-12" style="margin-top:15px;"> -->
        <div style="margin-top: 40px">
          <event />
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar/sidebar.vue";
import navbar from "@/components/Navbar/navbar.vue";
import event from "@/components/EventForm/Event.vue";
import mobilenav from "@/components/Navbar/mobilenav.vue";
export default {
  name: "LandingPage",
  components: {
    sidebar,
    navbar,
    event,
    mobilenav,
  },
};
</script>

<style scoped>
.mobile-nav{
  display: none;
}
@media screen and (max-width: 768px) {
  .desktop-nav{
    display:none;
  }
  .mobile-nav {
    display: block;
  }
  .side-nav{
    display: none;
  }
}
</style>
