<template>
  <div style="margin-top: 35px">
    <!-- <a-card style="border-radius: 20px"> -->
      <a-table :columns="columns" :data-source="data" :scroll="{ y: 450 }">
        <!-- <a slot="operation" slot-scope="text">
          <a :href="text.video" target="_blank">Video</a>
          </a> -->
        <!-- <a slot="name" slot-scope="text">{{ text }}</a> -->
        <a slot="link" slot-scope="text">
          <a :href="text.video" target="_blank">
          <img :src="text.image_url" alt="video Link">
          </a>
        </a>
        <a slot="action" slot-scope="text">
          <a-icon type="edit" style="font-size: 1.2rem" @click="edit(text)" />
          <!-- <a-divider type="vertical" /> -->
          <!-- <a-popconfirm
            title="Are you sure？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="confirm(text.id)"
          >
            <a-icon
              type="delete"
              style="font-size: 1.2rem"
              :style="{ color: 'hotpink' }"
            />
          </a-popconfirm> -->
        </a>
      </a-table>
    <!-- </a-card> -->
  </div>
</template>
<script>
import { bus } from "../../main";
import axios from "axios";
const columns = [
  {
    title: "न.",
    dataIndex: "sn",
    key: "sn",
    // width: "15%",
    defaultSortOrder: "ascend",
    sorter: (a, b) => a.sn - b.sn,
    sortDirections: ["descend", "ascend"],
    width: 100,
  },
  {
    title: "नाम",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "भिडियो",
    key: "video",
    scopedSlots: { customRender: "link" },
    // ellipsis: true,
  },
  {
    title: "कार्य",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
  // {
  //   title: "Action",
  //   key: "operation",
  //   scopedSlots: { customRender: "operation" },
  // },
];

const data = [];

export default {
  data() {
    return {
      data,
      columns,
    };
  },
  created() {
    bus.$on("store", (e) => {
      e ? this.fetchVideo() : "";
    });
    this.fetchVideo();
  },
  methods: {
    fetchVideo() {
      axios.get("/api/get-calendar-videos").then((res) => {
        this.data = res.data.data;
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].sn = i + 1;
        }
      });
    },
    edit(e) {
      bus.$emit("edit-video", e);
    },
  },
};
</script>

<style scoped>
.table-title {
  font-size: 1.5rem;
  margin-bottom: 0px;
  color: #254594;
  margin-bottom: 10px;
}
</style>
