<template>
  <div class="home">
    <landingPage />
  </div>
</template>

<script>
// @ is an alias to /src
import landingPage from "@/components/landingPage.vue";
export default {
  name: "HomeView",
  components: {
    landingPage,
  },
};
</script>
