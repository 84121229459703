<template>
  <div>
    <a-modal
      title="इभेन्ट विवरण"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancel">Cancel</a-button>
      </template>
      <div class="columns">
        <div class="column is-4">
          <span class="detail-title">मिति(BS)</span>
          <p class="detail">{{ event.date_np }}</p>
        </div>
        <div class="column is-4">
          <span class="detail-title">मिति(AD)</span>
          <p class="detail">{{ event.date_en }}</p>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <span class="detail-title">विवरण</span>
          <p class="detail">
            {{ event.event }}
          </p>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <span class="detail-title">श्रोत</span>
          <p class="detail">
            {{ event.source }}
          </p>
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
          <span class="detail-title">फाइल</span>
          <div v-if="event.pdf_url !== null">
            <a :href="event.pdf_url" target="_blank">
              <img
                src="https://www.learningcontainer.com/wp-content/uploads/2019/09/sample-pdf-files-for-testing-300x300.png"
                alt="फाइल"
                height="100"
                width="100"
              />
              <span class="detail">{{ event.pdf }}</span>
            </a>
          </div>
          <div v-else>
            <span class="detail"> कुनै फाइल फेला परेन।</span>
          </div>
        </div>
        <div class="column is-6">
          <span class="detail-title">भिडियो</span>
          <div v-if="event.video !== null">
            <a :href="event.video" target="_blank">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5wP4sz-fEUZW0z5hbQHq7KA8tX92HGzlEaQ&usqp=CAU"
                alt="भिडियो"
                height="100"
                width="100"
              />
              <!-- <span class="detail">{{ event.pdf }}</span> -->
            </a>
          </div>
          <div v-else>
            <span class="detail"> कुनै भिडियो फेला परेन।</span>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
          <span class="detail-title"> color </span>
          <div
            style="height: 20px; width: 50px"
            :style="{ 'background-color': '#' + event.color }"
          ></div>
        </div>
        <!-- <div class="column is-6">
          <span class="detail-title"> आइकन देखाइएको छ </span>
          <span class="detail-title"> आइकन देखाइएको छ </span>
        </div> -->
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      event: {},
    };
  },
  methods: {
    // A method that is called when the user clicks on the event. It takes the event as a parameter and
    // sets the event data to the event object in the data. It also sets the visible property to true.
    showModal(e) {
      this.event = e;
      this.visible = true;
    },
    // A method that is called when the user clicks on the event. It takes the event as a parameter and
    // sets the event data to the event object in the data. It also sets the visible property to true.
    handleOk(e) {
      this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    // A method that is called when the user clicks on the event. It takes the event as a parameter and
    // sets the event data to the event object in the data. It also sets the visible property to true.
    handleCancel(e) {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.detail {
  font-weight: bold;
  padding-bottom: 1%;
  color: #3c3c3c;
}
.detail-title {
  font-size: 0.8rem;
}
</style>
